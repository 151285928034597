.logo {
  display: block;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 240px;
  height: 37px;
}

.text {
  margin-bottom: 40px;
}