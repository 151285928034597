.home {
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 100%;
  max-width: 1024px;

  margin: 40px auto 0 auto;

  .divider {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }

  .group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  .card {
    width: calc((100% - 32px) / 3);
    @media screen and (max-width: 1024px) {
      width: calc((100% - 16px) / 2);
    }
    @media screen and (max-width: 768px) {
      width: 100%
    }

    min-height: 80px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
    text-decoration: none;

    transition: all 300ms ease-in-out;

    border-radius: 16px;
    background-color: var(--color-background-one);
    &.bgTwo {
      background-color: var(--color-background-two);
    }
    &.bgThree {
      background-color: var(--color-background-three);
    }

    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    }

  }

}