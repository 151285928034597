.filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;

  .left {
    flex: 1 1;
  }
  .right {
    flex: 1 1;
    text-align: right;
  }

}