.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 24px 20px;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;


  .left {
    flex: 1 1;
  }

  .right {
    flex: 1 1;
    text-align: right;
  }

}