.login {
  .content {
    max-width: 500px;
    margin: auto;
    padding: 24px 20px;
  }
}


.layout {
  .content {
    margin: auto;
    padding: 24px 20px;
  }
}

