.filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;

  .left {
    flex: 1 1;
  }
  .right {
    flex: 1 1;
    text-align: right;
  }

}

.expanded {
  display: flex;
  gap: 40px;
  .list {
    li {
      list-style-type: none;
      font-weight: bold;
      span {
        display: inline-block;
        width: 140px;
        font-weight: normal;
      }
    }
  }
}